import { useRef } from "react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";

export const DeleteConfirmation = (props) => {
  const { confirm, isOpen, onClose, headerText, bodyText } = props;
  const cancelRef = useRef();
  const confirmAction = () => {
    confirm();
    onClose();
  };

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {headerText}
          </AlertDialogHeader>
          <AlertDialogBody>{bodyText}</AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={onClose}
              colorScheme="gray"
              variant={"ghost"}
              className="btn-moment-delete-cancel"
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={confirmAction}
              ml="2"
              className="btn-moment-delete-confirm"
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
