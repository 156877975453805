import { Text, HStack, IconButton, Tooltip, Box } from "@chakra-ui/react";
import { recommendedMomentSettings } from "../../models/moment";
import { useSearchParams } from "react-router-dom";
import { updateURLParams } from "../../models/navigation";

interface Props {
  features: string[];
  urlParameter: string;
}

export const FeatureFilters = ({ features, urlParameter }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const requiredFeatures = searchParams.getAll(urlParameter) || [];

  const handleRequiredFeatures = (features: string | string[]) => {
    const newParams: Array<[string, string | string[]]> =
      typeof features === "string"
        ? [[urlParameter, features]]
        : [
            [
              urlParameter,
              features.sort((a, b) => {
                return a.toLowerCase().localeCompare(b.toLowerCase());
              }),
            ],
          ];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const featureFilterMenu = (feature: string) => (
    <Tooltip label={(recommendedMomentSettings as any)[feature].title}>
      <IconButton
        isActive={requiredFeatures.includes(feature)}
        size={"sm"}
        variant={requiredFeatures.includes(feature) ? "ghost" : "solid"}
        colorScheme={
          requiredFeatures.includes(feature)
            ? (recommendedMomentSettings as any)[feature].colorScheme
            : "gray"
        }
        aria-label={(recommendedMomentSettings as any)[feature].title}
        icon={(recommendedMomentSettings as any)[feature].icon}
        onClick={() => {
          handleRequiredFeatures(
            requiredFeatures.includes(feature)
              ? requiredFeatures.filter((f) => f !== feature)
              : [...requiredFeatures, feature]
          );
        }}
      />
    </Tooltip>
  );

  return (
    <HStack spacing={1}>
      <Text fontSize={"sm"} pr={1}>
        Features:
      </Text>
      {features.map((feat, index) => (
        <Box key={index}>{featureFilterMenu(feat)}</Box>
      ))}
    </HStack>
  );
};
