import {
  Flex,
  HStack,
  VStack,
  Text,
  Checkbox,
  Center,
  Spinner,
  IconButton,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import { useState, useEffect, useContext } from "react";
import { AddIcon, DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import _ from "lodash";
import { MomentItem } from "../MomentItem/MomentItem";
import { DeleteConfirmation } from "../DeleteConfirmation/DeleteConfirmation";
import { Moment, recommendedMomentSettings } from "../../../models/moment";
import { Action, defaultAction } from "../../../models/action";
import { VerticalFormatModal } from "../../VerticalFormatModal/VerticalFormatModal";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { DownloadsContext } from "../../../context/DownloadsContext";
import { ResultsURLParams } from "../../../models/navigation";
import { FeatureFilters } from "../../FeatureFilters/FeatureFilters";
import { useSearchParams } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";

interface Props {
  moments: Moment[];
  focusedCallback: (id: string | null) => void;
  focusedItem: string | null;
  deleteMoments: (ids: string[]) => void;
  updateMoment: (moment: Moment, update: Partial<Moment>) => void;
  searchId: string;
  videoInfo: any;
  pauseMedia: () => void;
}

export const UserMoments = ({
  moments,
  focusedCallback,
  focusedItem,
  deleteMoments,
  updateMoment,
  searchId,
  videoInfo,
  pauseMedia,
}: Props) => {
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const momentsLoading = videoInfo === null;
  const { getFlag } = useKindeAuth();
  const demoUser = getFlag("demo-user").value;

  const [searchParams] = useSearchParams();
  const requiredFeatures = searchParams.getAll(ResultsURLParams.ResultsFeatures) || [];

  const { downloaderRequest, verticalFormatClips, setVerticalFormatClips } =
    useContext(DownloadsContext);

  const verticalFormat = (action: Action) => {
    createDownloaderRequest(
      verticalFormatClips.map((v) => v.momentId),
      [action]
    );
  };

  const createDownloaderRequest = (
    momentIds: string[],
    actions?: Action[],
    forVerticalFormat?: boolean
  ) => {
    const downloadMoments: Moment[] = [];
    momentIds.forEach((id) => {
      const moment = moments.find((item) => item.id === id);
      if (moment) downloadMoments.push(moment);
    });
    downloaderRequest(downloadMoments, searchId, actions ?? [defaultAction], forVerticalFormat);
  };

  useEffect(() => {
    setCheckedItems(checkedItems.filter((id) => moments.some((moment) => moment.id === id)));
  }, [moments]);

  const orderedMoments = [...moments].sort((a, b) => {
    const getLevel = (annotations: Record<string, number>, features: string[]) => {
      return features.reduce(
        (lvl, feat) =>
          lvl +
          (annotations?.[feat] >= (recommendedMomentSettings as any)[feat].featureThreshold
            ? 1
            : 0),
        0
      );
    };
    if (requiredFeatures.length > 0) {
      const aLevel = getLevel(a.annotations, requiredFeatures);
      const bLevel = getLevel(b.annotations, requiredFeatures);
      if (aLevel !== bLevel) return bLevel - aLevel;
    }
    return a.start_time - b.start_time;
  });

  const checkCallback = (id: string) => {
    if (checkedItems.includes(id)) setCheckedItems(checkedItems.filter((item) => item !== id));
    else setCheckedItems([...checkedItems, id]);
  };

  const selectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) setCheckedItems(moments.map((result) => result.id));
    else setCheckedItems([]);
  };

  const deselectMoment = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      focusedCallback(null);
    }
  };

  const verticalReformat = (momentId: string) => {
    createDownloaderRequest([momentId], [defaultAction], true);
    setVerticalFormatClips([{ momentId }]);
    pauseMedia();
  };

  const canAccessMoments = videoInfo && videoInfo.status === "stopped";

  const handleAmplitudeClick = (e: string) => {
    amplitude.track(e);
  };

  const features = [
    // Maps to recommendedMomentSettings in models/moment.tsx
    "Hype",
    "ASR positive",
    "ASR negative",
    "Chat positive",
    "Chat negative",
  ];

  return (
    <Flex
      className={"results-column"}
      flexDirection={"column"}
      w={"100%"}
      h={"100%"}
      onClick={deselectMoment}
      pt={1}
    >
      {verticalFormatClips.length > 0 && (
        <VerticalFormatModal
          verticalFormatClips={verticalFormatClips}
          onClose={() => setVerticalFormatClips([])}
          onConfirm={verticalFormat}
        />
      )}
      {!canAccessMoments || moments.length === 0 ? (
        <Center flexDirection={"column"} height={"100%"} onClick={() => focusedCallback(null)}>
          {!canAccessMoments ? (
            <>
              <HStack>
                <Text fontSize={"lg"} fontWeight={"medium"} textAlign={"center"}>
                  Analyzing...
                </Text>
                <Spinner size={"sm"} />
              </HStack>
              <Text
                mt={2}
                color={"gray.300"}
                fontSize={"md"}
                textAlign={"center"}
                sx={{ textWrap: "balance" }}
              >
                Create Moments by clicking on the Timeline then selecting the{" "}
                <IconButton
                  aria-label={"Add Moment Button"}
                  colorScheme={"green"}
                  variant={"solid"}
                  size={"xs"}
                  icon={<AddIcon />}
                  pointerEvents={"none"}
                />{" "}
                button once analysis has completed.
              </Text>
            </>
          ) : momentsLoading ? (
            <HStack>
              <Text fontSize={"lg"} fontWeight={"medium"} textAlign="center">
                Loading...
              </Text>
              <Spinner size={"sm"} />
            </HStack>
          ) : (
            <>
              <Text fontSize={"lg"} fontWeight={"medium"} textAlign={"center"}>
                No Moments
              </Text>
              <Text
                mt={2}
                color={"gray.300"}
                fontSize={"md"}
                textAlign={"center"}
                sx={{ textWrap: "balance" }}
              >
                Create Moments by clicking on the Timeline then selecting the{" "}
                <IconButton
                  aria-label={"Add Moment Button"}
                  colorScheme={"green"}
                  variant={"solid"}
                  size={"xs"}
                  icon={<AddIcon />}
                  pointerEvents={"none"}
                />{" "}
                button.
              </Text>
            </>
          )}
        </Center>
      ) : (
        <>
          <Box mt={3}>
            <FeatureFilters features={features} urlParameter={ResultsURLParams.ResultsFeatures} />
          </Box>
          <HStack flex={"0 0 100%"} justifyContent={"space-between"} maxHeight={"32px"} mt={2}>
            <Checkbox
              className={"inp-select-all-results"}
              w={"auto"}
              ml={4}
              colorScheme={"teal"}
              size={"md"}
              color={"gray.300"}
              onChange={selectAll}
              isChecked={checkedItems.length > 0 && checkedItems.length === moments.length}
            >
              <Text color={"gray.300"} fontSize={"xs"} textTransform={"uppercase"}>
                Select all
              </Text>
            </Checkbox>

            <HStack>
              {checkedItems.length > 0 && (
                <Text fontSize="xs" textTransform="uppercase" fontWeight="medium" color="gray.300">
                  {checkedItems.length} Selected
                </Text>
              )}
              <Tooltip label={"Download selected"}>
                <IconButton
                  size={"sm"}
                  icon={<DownloadIcon boxSize={4} />}
                  aria-label={"Download all selected"}
                  onClick={() => {
                    createDownloaderRequest(checkedItems);
                    handleAmplitudeClick("Download Bulk Moment");
                  }}
                  className="po-btn-select-all-download"
                  isDisabled={checkedItems.length === 0}
                >
                  Download
                </IconButton>
              </Tooltip>
              {!demoUser && (
                <Tooltip label={"Delete selected"}>
                  <IconButton
                    size={"sm"}
                    icon={<DeleteIcon />}
                    aria-label={"Delete all selected"}
                    onClick={() => setShowDeleteConfirmation(true)}
                    className="po-btn-select-all-delete-moment"
                    isDisabled={checkedItems.length === 0}
                  >
                    Delete
                  </IconButton>
                </Tooltip>
              )}
            </HStack>
            <DeleteConfirmation
              confirm={() => deleteMoments(checkedItems)}
              isOpen={showDeleteConfirmation}
              onClose={() => setShowDeleteConfirmation(false)}
              headerText={"Delete Moments"}
              bodyText={"Are you sure you want to delete all selected Moments?"}
            />
          </HStack>
          <VStack
            className={"result-list"}
            mt={2}
            overflowY={"auto"}
            overflowX={"hidden"}
            flex={1}
            onClick={deselectMoment}
            gap={0}
          >
            {orderedMoments.map((moment) => (
              <MomentItem
                key={moment.id}
                inputs={{
                  kind: "UserMoment",
                  moment,
                  downloadCallback: () => createDownloaderRequest([moment.id]),
                  deleteMoments,
                  updateMoment,
                  verticalReformat: () => verticalReformat(moment.id),
                }}
                checkCallback={checkCallback}
                focusedCallback={focusedCallback}
                isChecked={checkedItems.some((item) => item === moment.id)}
                isFocused={focusedItem === moment.id}
                features={features}
              />
            ))}
          </VStack>
        </>
      )}
    </Flex>
  );
};
