import { useState } from "react";
import {
  Flex,
  HStack,
  VStack,
  Text,
  Center,
  Spinner,
  Box,
  useToast,
  Button,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { RecommendedFilterPopover } from "../RecommendedFilterPopover/RecommendedFilterPopover";
import { RecommendedMoment } from "../../../models/moment";
import { MomentItem } from "../MomentItem/MomentItem";
// import * as amplitude from "@amplitude/analytics-browser";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { SortThree } from "@icon-park/react";
import { FeatureFilters } from "../../FeatureFilters/FeatureFilters";
import { ResultsURLParams } from "../../../models/navigation";

interface Props {
  moments: RecommendedMoment[];
  focusedCallback: (id: string | null) => void;
  focusLastMoment: () => void;
  focusedItem: string | null;
  momentsLoading: boolean;
  acceptMoments: (recommended: RecommendedMoment[]) => void;
  toggleMoment: (id: string) => void;
  videoInfo: any;
  recommendedAmount: number;
  setRecommendedAmount: (amount: number) => void;
  minRecommendedAmount: number;
  maxRecommendedAmount: number;
}

type SortOption = "level" | "time";

export const RecommendedMoments = ({
  moments,
  focusedCallback,
  focusLastMoment,
  focusedItem,
  momentsLoading,
  acceptMoments,
  toggleMoment,
  videoInfo,
  recommendedAmount,
  setRecommendedAmount,
  minRecommendedAmount,
  maxRecommendedAmount,
}: Props) => {
  // const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const toast = useToast();
  // const shownMoments = moments.filter((moment) => !moment.rejected);
  const { getFlag } = useKindeAuth();
  const canSeeMoreMoments = getFlag("more-moments", false).value as boolean;
  const [sortOption, setSortOption] = useState<SortOption>("level");

  // useEffect(() => {
  //   const hiddenMoments = moments.filter((moment) => moment.rejected).map((moment) => moment.id);
  //   const newCheckedItems = checkedItems.filter((item) => !hiddenMoments.includes(item));
  //   if (newCheckedItems.length !== checkedItems.length) setCheckedItems(newCheckedItems);
  // }, [moments]);

  const orderedMoments = [...moments].sort((a, b) => {
    if (a.rejected !== b.rejected) {
      return Number(a.rejected) - Number(b.rejected);
    }
    if (sortOption === "level") {
      return b.level - a.level;
    }
    return a.time - b.time;
  });

  // const checkCallback = (id: string) => {
  //   if (checkedItems.includes(id)) setCheckedItems(checkedItems.filter((item) => item !== id));
  //   else setCheckedItems([...checkedItems, id]);
  // };

  const deselectMoment = (event: React.MouseEvent) => {
    if (event.target === event.currentTarget) {
      focusedCallback(null);
    }
  };

  // const selectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) setCheckedItems(shownMoments.map((moment) => moment.id));
  //   else setCheckedItems([]);
  // };

  const notifyAcceptedMoments = (count: number) => {
    toast({
      render: () => (
        <HStack spacing={4} bg={"green.200"} color={"gray.900"} px={4} py={3} borderRadius={"md"}>
          <CheckCircleIcon boxSize={"20px"} />
          <Text>
            {count} Moment{count > 1 ? "s" : ""} added to My Moments
          </Text>
          <Button
            onClick={focusLastMoment}
            size={"sm"}
            variant={"solid"}
            colorScheme={"blackAlpha"}
          >
            View
          </Button>
        </HStack>
      ),
    });
  };

  // const acceptMomentsInter = () => {
  //   acceptMoments(moments.filter((moment) => checkedItems.includes(moment.id)));
  //   notifyAcceptedMoments(checkedItems.length);
  // };

  const acceptMoment = (moment: RecommendedMoment) => {
    acceptMoments([moment]);
    notifyAcceptedMoments(1);
  };

  // const handleAmplitudeClick = (e: string) => {
  //   amplitude.track(e);
  // };

  const canAccessMoments = videoInfo && videoInfo.status === "stopped";

  const features = [
    // Maps to recommendedMomentSettings in models/moment.tsx
    "Hype",
    "ASR positive",
    "ASR negative",
    "Chat positive",
    "Chat negative",
  ];

  return (
    <Flex
      className={"results-column"}
      flexDirection={"column"}
      w={"100%"}
      h={"100%"}
      onClick={deselectMoment}
      pt={1}
      overflowY={"auto"}
    >
      {!canAccessMoments || moments.length === 0 ? (
        <Center flexDirection={"column"} height={"100%"} onClick={() => focusedCallback(null)}>
          {!canAccessMoments ? (
            <>
              <HStack>
                <Text fontSize={"lg"} fontWeight={"medium"} textAlign={"center"}>
                  Analyzing...
                </Text>
                <Spinner size={"sm"} />
              </HStack>
              <Text
                mt={2}
                color={"gray.300"}
                fontSize={"md"}
                textAlign={"center"}
                sx={{ textWrap: "balance" }}
              >
                Recommended Moments will appear here once analysis has completed.
              </Text>
            </>
          ) : momentsLoading && false ? (
            <Flex flexDirection={"row"} alignItems={"center"}>
              <Text fontSize={"lg"} fontWeight={"medium"} mr={2} textAlign={"center"}>
                Loading...
              </Text>
              <Spinner size={"sm"} />
            </Flex>
          ) : (
            <>
              <Text fontSize={"lg"} fontWeight={"medium"} textAlign={"center"}>
                No Moments
              </Text>
              <Text
                mt={2}
                color={"gray.300"}
                fontSize={"md"}
                textAlign={"center"}
                sx={{ textWrap: "balance" }}
              >
                Recommended Moments will appear here after your video is analyzed.
              </Text>
            </>
          )}
        </Center>
      ) : (
        <>
          <HStack
            spacing={1}
            flex={"0 0 100%"}
            justifyContent={"space-between"}
            maxHeight={"32px"}
            mt={3}
          >
            {/* <Checkbox
              className="inp-select-all-results"
              w="auto"
              ml="4"
              colorScheme={"teal"}
              size="md"
              color="gray.400"
              onChange={selectAll}
              isChecked={checkedItems.length > 0 && checkedItems.length === shownMoments.length}
            >
              <Text color="gray.400" fontSize="xs" fontWeight="medium" textTransform="uppercase">
                Select all
              </Text>
            </Checkbox>
            <Flex alignItems="center">
            {checkedItems.length > 0 && (
                <Text fontSize="xs" mr="3" fontWeight="medium" color="gray.400">
                  {checkedItems.length} Selected
                </Text>
              )} */}
            <FeatureFilters features={features} urlParameter={ResultsURLParams.ResultsFeatures} />
            <HStack>
              <Box>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    icon={<SortThree />}
                    size={"sm"}
                    aria-label={"Sort recommended moments"}
                  />
                  <MenuList>
                    <MenuOptionGroup
                      title={"Sort by"}
                      type={"radio"}
                      value={sortOption}
                      onChange={(value) => setSortOption(value as SortOption)}
                    >
                      <MenuItemOption value={"level"}>Rank</MenuItemOption>
                      <MenuItemOption value={"time"}>Timestamp</MenuItemOption>
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </Box>
              {canSeeMoreMoments && (
                <Box>
                  <RecommendedFilterPopover
                    recommendedAmount={recommendedAmount}
                    setRecommendedAmount={setRecommendedAmount}
                    minRecommendedAmount={minRecommendedAmount}
                    maxRecommendedAmount={maxRecommendedAmount}
                  />
                </Box>
              )}
            </HStack>
            {/* <Tooltip label="Add to My Moments">
                <IconButton
                  aria-label="Accept"
                  isDisabled={checkedItems.length === 0}
                  className="btn-po-accept moment-action"
                  colorScheme="gray"
                  variant="solid"
                  size="sm"
                  onClick={() => {
                    handleAmplitudeClick("Add Recommended Moments Bulk");
                    acceptMomentsInter();
                  }}
                  icon={<CheckIcon />}
                />
              </Tooltip>
            </Flex> */}
          </HStack>

          <VStack
            className={"result-list"}
            mt={3}
            overflowY={"auto"}
            overflowX={"hidden"}
            flex={1}
            gap={0}
            onClick={deselectMoment}
          >
            {orderedMoments.map((moment) => (
              <MomentItem
                key={moment.id}
                inputs={{
                  kind: "RecommendedMoment",
                  moment,
                  accept: () => acceptMoment(moment),
                  toggle: () => toggleMoment(moment.id),
                }}
                checkCallback={(_) => {
                  return;
                }}
                focusedCallback={focusedCallback}
                isChecked={false}
                isFocused={focusedItem === moment.id}
                features={features}
              />
            ))}
          </VStack>
        </>
      )}
    </Flex>
  );
};
