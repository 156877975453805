import {
  Flex,
  Text,
  Checkbox,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Badge,
  Wrap,
  Tooltip,
  Box,
  Tag,
  HStack,
  Editable,
  EditablePreview,
  EditableInput,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  WrapItem,
  PopoverHeader,
  PopoverCloseButton,
  useToast,
} from "@chakra-ui/react";
import { MoreOne, Plus, ScreenRotation, Undo } from "@icon-park/react";
import { secondsToHMSDuration } from "../../../utils/time";
import { CheckIcon, CloseIcon, DeleteIcon, DownloadIcon, EditIcon } from "@chakra-ui/icons";
import "./MomentItem.css";
import { DeleteConfirmation } from "../DeleteConfirmation/DeleteConfirmation";
import { EditMoment } from "../../EditMoment/EditMoment";
import { useEffect, useRef, useState } from "react";
import { Moment, RecommendedMoment, recommendedMomentSettings } from "../../../models/moment";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import * as amplitude from "@amplitude/analytics-browser";
import { pillOptions } from "../../../models/tags";

export type UserMomentInputs = {
  kind: "UserMoment";
  moment: Moment;
  downloadCallback: () => void;
  deleteMoments: (ids: string[]) => void;
  updateMoment: (moment: Moment, update: Partial<Moment>) => void;
  verticalReformat: () => void;
};
export type RecommendedMomentInputs = {
  kind: "RecommendedMoment";
  moment: RecommendedMoment;
  accept: () => void;
  toggle: () => void;
};
export type MomentInputs = UserMomentInputs | RecommendedMomentInputs;

interface Props {
  inputs: MomentInputs;
  checkCallback: (id: string) => void;
  focusedCallback: (id: string | null) => void;
  isChecked: boolean;
  isFocused: boolean;
  features: string[];
}

export const MomentItem = ({
  inputs,
  checkCallback,
  focusedCallback,
  isChecked,
  isFocused,
  features,
}: Props) => {
  const maxTags = 2;

  const ref = useRef<HTMLDivElement>(null);
  const data = inputs.moment;

  const [tags, setTags] = useState(data.tags);
  const [showEditMoment, setShowEditMoment] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { getFlag } = useKindeAuth();
  const demoUser = getFlag("demo-user").value;

  const [originalTitle, setOriginalTitle] = useState(data.title);
  const [currentTitle, setcurrentTitle] = useState(data.title);
  const toast = useToast();

  const selectMoment = () => {
    if (inputs.kind === "UserMoment" || !inputs.moment.rejected)
      focusedCallback(isFocused ? null : data.id);
  };
  const toggle = () => {
    if (inputs.kind === "RecommendedMoment") {
      if (isFocused) focusedCallback(null);
      inputs.toggle();
    }
  };

  const scrollIntoView = (div: HTMLDivElement) => {
    if (!div.parentElement) return;
    const rect = div.getBoundingClientRect();
    const parentRect = div.parentElement.getBoundingClientRect();
    if (rect.bottom < parentRect.top || rect.top > parentRect.bottom) div.scrollIntoView();
  };

  const momentFeatureTag = (label: string, index: number) => (
    <Tooltip key={index} label={(recommendedMomentSettings as any)[label].title}>
      <Tag
        colorScheme={(recommendedMomentSettings as any)[label].colorScheme}
        variant={"subtle"}
        size={"xl"}
        py={0.5}
        borderRadius={"sm"}
        minW={"24px"}
        justifyContent={"center"}
        className={
          (recommendedMomentSettings as any)[label].hyperThreshold &&
          data.annotations[label] >= (recommendedMomentSettings as any)[label].hyperThreshold
            ? "hyper-hype"
            : ""
        }
      >
        {(recommendedMomentSettings as any)[label].icon}
      </Tag>
    </Tooltip>
  );

  useEffect(() => {
    if (isFocused && ref.current) scrollIntoView(ref.current);
  }, [isFocused]);

  const handleTagToggle = (tag: string) => {
    if (inputs.kind === "RecommendedMoment") return;

    if (tags.includes(tag)) {
      setTags(tags.filter((t) => t !== tag));
    } else {
      setTags([...tags, tag]);
    }
  };

  const updateTags = () => {
    if (inputs.kind === "RecommendedMoment") return;
    if (inputs.moment.tags === tags) return;

    inputs.updateMoment(inputs.moment, { tags: tags });
  };

  const handleAmplitudeClick = (e: string) => {
    amplitude.track(e);
  };

  return (
    <Flex
      borderRadius={"md"}
      w={"100%"}
      _hover={{ bg: "gray.600" }}
      bg={"gray.700"}
      pl={4}
      pr={3}
      className={`result-item-${data.id} momentItem ${isFocused ? "momentItem-focused" : ""}`}
      pos={"relative"}
      ref={ref}
      borderWidth={1}
      borderColor={isFocused ? "transparent" : ""}
      borderStyle={"inset"}
    >
      {isFocused && (
        <Box
          pos={"absolute"}
          h={"100%"}
          w={"100%"}
          top={0}
          left={0}
          borderWidth={2}
          borderColor={"teal.300"}
          borderRadius={"md"}
          pointerEvents={"none"}
        />
      )}
      {inputs.kind !== "RecommendedMoment" && (
        <Checkbox
          className={`inp-result-item-checkbox inp-result-item-${data.id}`}
          w={4}
          mr={4}
          colorScheme={"teal"}
          size={"md"}
          onChange={() => checkCallback(data.id)}
          isChecked={isChecked}
          display={"inherit"}
        />
      )}
      <Flex w={"100%"} justifyContent={"space-between"} alignItems={"center"}>
        <Flex
          flexDirection={"column"}
          minW={"150px"}
          w={"100%"}
          onClick={selectMoment}
          py={2}
          gap={inputs.kind === "UserMoment" ? 0.5 : 1}
        >
          {inputs.kind === "UserMoment" ? (
            <HStack w={"100%"} justifyContent={"space-between"} gap={2}>
              <Editable
                h={6}
                value={currentTitle}
                submitOnBlur
                onChange={(newTitle) => setcurrentTitle(newTitle)}
                onSubmit={(newTitle) => {
                  if (!newTitle.trim()) {
                    toast({
                      title: "Error",
                      description: "Moment title cannot be empty",
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                    });
                    setcurrentTitle(originalTitle);
                  } else {
                    inputs.updateMoment(inputs.moment, { title: newTitle });
                    setOriginalTitle(newTitle);
                  }
                }}
                mr={2}
                w={"100%"}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isFocused) focusedCallback(data.id);
                }}
              >
                <HStack h={6} mr={2} w={"100%"}>
                  <EditablePreview
                    title={originalTitle}
                    noOfLines={1}
                    borderRadius={"sm"}
                    p={0}
                    h={6}
                    w={"100%"}
                    maxW={"14.25rem"}
                    _hover={{ bg: "whiteAlpha.200" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!isFocused) focusedCallback(data.id);
                    }}
                  />
                  <Input
                    as={EditableInput}
                    borderRadius={"sm"}
                    noOfLines={1}
                    p={0}
                    h={6}
                    w={"100%"}
                    maxLength={50}
                  />
                </HStack>
              </Editable>
              <Text
                color={"gray.300"}
                fontSize={"xs"}
                userSelect={"none"}
                w={"max-content"}
                className={"moment-time"}
              >
                {secondsToHMSDuration(data.start_time)}
              </Text>
            </HStack>
          ) : (
            <HStack w={"100%"} justifyContent={"space-between"} gap={2}>
              <Text noOfLines={1} title={data.title}>
                {data.title}
              </Text>
              <Text
                color={"gray.300"}
                fontSize={"xs"}
                userSelect={"none"}
                w={"max-content"}
                className={"moment-time"}
              >
                {secondsToHMSDuration(data.start_time)}
              </Text>
            </HStack>
          )}

          <HStack
            w={"100%"}
            maxW={28}
            spacing={0.5}
            h={6}
            className={"moment-features"}
            _empty={{ display: "none" }}
          >
            {features
              .filter(
                (f) => data.annotations[f] >= (recommendedMomentSettings as any)[f].featureThreshold
              )
              .map((feature, index) => momentFeatureTag(feature, index))}
          </HStack>
          {inputs.kind === "UserMoment" && (
            <HStack gap={1} h={6}>
              <Popover placement={"bottom-end"} strategy={"fixed"} onClose={updateTags}>
                <PopoverTrigger>
                  <IconButton
                    aria-label={"Edit moment tags"}
                    icon={<Plus />}
                    h={"1.1rem"}
                    minW={"1.1rem"}
                    fontSize={"xs"}
                    variant={"solid"}
                    borderRadius={"sm"}
                    colorScheme={"gray"}
                    bg={"whiteAlpha.300"}
                    _hover={{ bg: "whiteAlpha.400" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!isFocused) focusedCallback(data.id);
                    }}
                  />
                </PopoverTrigger>

                <PopoverContent
                  onClick={(e) => e.stopPropagation()}
                  maxW={"21.5rem"}
                  w={"100%"}
                  shadow={"dark-lg"}
                >
                  <PopoverHeader>
                    <Text userSelect={"none"} fontSize={"sm"}>
                      Tags
                    </Text>
                    <PopoverCloseButton top={1.5} right={1.5} />
                  </PopoverHeader>
                  <Wrap p={3}>
                    {pillOptions
                      .sort((a, b) => a.label.localeCompare(b.label))
                      .map((option) => (
                        <WrapItem key={option.label}>
                          <Button
                            size={"xs"}
                            key={option.label}
                            variant={tags.includes(option.label) ? "solid" : "outline"}
                            colorScheme={tags.includes(option.label) ? "teal" : "gray"}
                            width={"fit-content"}
                            display={"inline-block"}
                            fontWeight={"normal"}
                            onClick={() => handleTagToggle(option.label)}
                            borderWidth={tags.includes(option.label) ? 1 : 1}
                          >
                            {option.label}
                          </Button>
                        </WrapItem>
                      ))}
                  </Wrap>
                </PopoverContent>
              </Popover>
              {data.tags.length > 0 && (
                <>
                  {data.tags.slice(0, maxTags).map((label, index) => (
                    <Badge
                      key={index}
                      width={"fit-content"}
                      variant={"subtle"}
                      fontWeight={"medium"}
                      letterSpacing={"wide"}
                      fontSize={"xs"}
                      userSelect={"none"}
                      textTransform={"capitalize"}
                    >
                      {label}
                    </Badge>
                  ))}
                  {data.tags.length > maxTags && (
                    <Tooltip label={data.tags.slice(maxTags).join(", ")}>
                      <Badge
                        width={"fit-content"}
                        variant={"subtle"}
                        fontWeight={"medium"}
                        letterSpacing={"wide"}
                        fontSize={"xs"}
                        size={"xs"}
                        userSelect={"none"}
                        textTransform={"capitalize"}
                      >
                        +{data.tags.length - maxTags}
                      </Badge>
                    </Tooltip>
                  )}
                </>
              )}
            </HStack>
          )}
        </Flex>
      </Flex>
      {inputs.kind === "UserMoment" ? (
        <HStack w={"fit-content"} h={"100%"} justifyContent={"end"} mr={1}>
          <Tooltip label={"Download"}>
            <IconButton
              aria-label={"Download"}
              className={"btn-po-download moment-action"}
              colorScheme={"gray"}
              variant={"outline"}
              size={"sm"}
              onClick={() => {
                inputs.downloadCallback();
                handleAmplitudeClick("Download Single Moment");
              }}
              icon={<DownloadIcon boxSize={4} />}
            />
          </Tooltip>
          <Tooltip label={"Reformat to Vertical"}>
            <IconButton
              aria-label={"Reformat to Vertical"}
              className={"btn-po-reformat moment-action"}
              colorScheme={"gray"}
              variant={"outline"}
              size={"sm"}
              onClick={() => {
                handleAmplitudeClick("Start Reformat to Vertical");
                inputs.verticalReformat();
              }}
              icon={<ScreenRotation size={18} style={{ transform: "scaleY(-1)rotate(75deg)" }} />}
            />
          </Tooltip>
          <Box>
            <Menu isLazy>
              <MenuButton
                className={"moment-action"}
                as={IconButton}
                variant={"outline"}
                colorScheme={"gray"}
                size={"sm"}
                icon={<MoreOne size={24} />}
              />
              <MenuList>
                <MenuItem
                  icon={<EditIcon />}
                  onClick={() => {
                    handleAmplitudeClick("Edit Moment Clicked");
                    setShowEditMoment(true);
                  }}
                >
                  Edit
                </MenuItem>
                {!demoUser && (
                  <MenuItem
                    icon={<DeleteIcon />}
                    onClick={() => {
                      setShowDeleteConfirmation(true);
                      handleAmplitudeClick("Delete Moment Clicked");
                    }}
                  >
                    Delete
                  </MenuItem>
                )}
              </MenuList>
              {showEditMoment && (
                <EditMoment
                  confirm={(info) => {
                    inputs.updateMoment(inputs.moment, info);
                    handleAmplitudeClick("Moment Edit Confirmed");
                  }}
                  onClose={() => setShowEditMoment(false)}
                  confirmButtonText="Confirm"
                  initialData={data}
                />
              )}
              <DeleteConfirmation
                confirm={() => {
                  inputs.deleteMoments([data.id]);
                  handleAmplitudeClick("Moment Delete Confirmed");
                }}
                isOpen={showDeleteConfirmation}
                onClose={() => setShowDeleteConfirmation(false)}
                headerText={"Delete Moment"}
                bodyText={"Are you sure you want to delete this Moment?"}
              />
            </Menu>
          </Box>
        </HStack>
      ) : (
        <HStack h={"100%"} justify={"end"} mr={1}>
          <Tooltip label={"Add to My Moments"}>
            <IconButton
              aria-label={"Accept"}
              className={"btn-po-accept moment-action"}
              colorScheme={"gray"}
              variant={"outline"}
              size={"sm"}
              onClick={() => {
                inputs.accept();
                handleAmplitudeClick("Accept Recommended Moment");
              }}
              icon={<CheckIcon />}
              display={!inputs.moment.rejected ? "static" : "none"}
            />
          </Tooltip>
          <Tooltip label={!inputs.moment.rejected ? "Reject Moment" : "Undo"}>
            <IconButton
              aria-label={!inputs.moment.rejected ? "Reject" : "Undo"}
              className={"btn-po-toggle moment-action"}
              colorScheme={"gray"}
              variant={"outline"}
              size={"sm"}
              onClick={() => {
                toggle();
                if (!inputs.moment.rejected) {
                  handleAmplitudeClick("Reject Recommended Moment");
                } else {
                  handleAmplitudeClick("Undo Recommended Moment Rejection");
                }
              }}
              icon={!inputs.moment.rejected ? <CloseIcon boxSize={3} /> : <Undo size={16} />}
            />
          </Tooltip>
        </HStack>
      )}
      {inputs.kind === "RecommendedMoment" && inputs.moment.rejected && (
        <Box
          pos={"absolute"}
          borderRadius={"md"}
          w={"100%"}
          h={"100%"}
          bg={"blackAlpha.500"}
          top={0}
          left={0}
          pointerEvents={"none"}
        />
      )}
    </Flex>
  );
};
