export const enum RivrLocations {
  AccessDenied = "/403",
  Account = "/account",
  Campaigns = "/campaigns",
  KeyManager = "/key-manager",
  KnowledgeBase = "/kb",
  Results = "/results",
  Search = "/search",
  StatsForNerds = "/statsfornerds",
}

export const enum KBSubPages {
  GettingStarted = "getting-started",
  Campaigns = "campaigns",
  Results = "results",
  Timeline = "timeline",
  Moments = "moments",
  ChatSpeech = "chat-speech",
  Search = "search",
  Support = "support",
  // B2B Pages
  Organization = "organization",
  OrgCampaigns = "org-campaigns",
  Analyze = "analyze",
  Discover = "discover",
}

export const enum CampaignSubPages {
  Campaigns = "campaigns",
  Analyze = "analyze",
  Discover = "discover",
  Insights = "insights",
}

export const enum CampaignURLParams {
  SubPage = "pg",
  SelectedOrganization = "org",
  SelectedCampaign = "cp",
  SelectedChannels = "ch",
  DateFilterStart = "dfs",
  DateFilterEnd = "dfe",
  // Analyze
  ChannelSortOption = "cso",
  ChannelSortDirection = "csd",
  ChannelFilterText = "cft",
  ItemFilterText = "ift",
  ItemSortOption = "iso",
  ItemSortDirection = "isd",
  SelectedStatuses = "st",
  SelectedLanguages = "lg",
  // Discover
  DiscoverSelectedView = "dsv",
  DiscoverFeatures = "dft",
  /// Moments
  MomentSortOption = "mso",
  MomentSortDirection = "msd",
  MomentTypeFilter = "mtf",
  MomentStatusFilter = "msf",
  MomentPageSize = "mps",
  /// Mentions
  MentionSortOption = "nso",
  MentionSortDirection = "nsd",
  MentionTypeFilter = "ntf",
  MentionPageSize = "nps",
}

export const enum AccountURLParams {
  SelectedTab = "tab",
  SelectedOrganization = "org",
}

export const enum ResultsURLParams {
  ResultsFeatures = "rft",
  SelectedMoment = "mmt",
  SelectedTimestamp = "t",
  SelectedPanel = "pnl",
}

export const updateURLParams = (
  currentParams: string,
  newParams: Array<[string, string | string[]]>
) => {
  const result = new URLSearchParams(currentParams);
  newParams.forEach((p) => {
    if (typeof p[1] === "string") {
      if (p[1] === "") {
        result.delete(p[0]);
      } else {
        result.set(p[0], p[1]);
      }
    } else {
      result.delete(p[0]);
      p[1].forEach((v) => {
        result.append(p[0], v);
      });
    }
  });
  return result.toString();
};
