import { useMemo, useState } from "react";
import {
  HStack,
  IconButton,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  MenuDivider,
  Button,
  Input,
  FormControl,
  FormHelperText,
  Select,
  Tooltip,
  Box,
  Divider,
} from "@chakra-ui/react";
import { DoubleLeft, DoubleRight, Filter, FullScreenPlay, Left, Right } from "@icon-park/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import "../CampaignDiscover.css";
import { usePagination } from "../../../../hooks/usePagination";
import { getMaxRank, mentionDisplaySettings, RivrMention } from "../../../../models/mention";
import { RivrSearch } from "../../../../models/rivrSearch";
import { useSearchParams } from "react-router-dom";
import {
  CampaignURLParams,
  ResultsURLParams,
  RivrLocations,
  updateURLParams,
} from "../../../../models/navigation";
import DiscoverViewSelect from "../DiscoverViewSelect";
import { DiscoverMentionItem } from "./DiscoverMentionItem";
import { offsetDate, secondsToHMSDuration } from "../../../../utils/time";
import DiscoverMentionsSearch from "./DiscoverMentionsSearch";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import MiniPlayer from "../../../MiniPlayer/MiniPlayer";
import { Loading } from "../../../Loading/Loading";
import { FeatureFilters } from "../../../FeatureFilters/FeatureFilters";

import Cookies from "js-cookie";

import MentionsReport from "./Reporting/MentionsReport";

interface Props {
  searches: RivrSearch[];
}

const CampaignDiscoverMentions = ({ searches }: Props) => {
  const { getFlag } = useKindeAuth();
  const isAdmin = Cookies.get("xHasuraRole") === "admin";
  const miniPlayerAllowed = getFlag("mini-player", false).value as boolean;
  const hasMentionsReporting = getFlag("mentions-reporting", false).value as boolean;
  const mentionsSentimentEnabled = getFlag("mentions-sentiment", false).value as boolean;

  const [keywords, setKeywords] = useState<string[]>([]);
  const [mentions, setMentions] = useState<RivrMention[]>([]);
  const [mentionsLoading, setMentionsLoading] = useState<boolean>(false);
  const [queryTime, setQueryTime] = useState<number>(0);
  const [failedSearch, setFailedSearch] = useState<boolean>(false);
  const [miniPlayer, setMiniPlayer] = useState(() => {
    const storedState = localStorage.getItem("miniPlayer");
    return storedState ? JSON.parse(storedState) : false;
  });
  const [selectedMention, setSelectedMention] = useState<RivrMention | null>(null);
  const [playerScale, setPlayerScale] = useState(1);

  const [searchParams, setSearchParams] = useSearchParams();
  const selectedCampaign = searchParams.get(CampaignURLParams.SelectedCampaign) || "";
  const selectedChannels = searchParams.getAll(CampaignURLParams.SelectedChannels) || [];
  const sortOption = searchParams.get(CampaignURLParams.MentionSortOption) || "rank";
  const sortDirection = searchParams.get(CampaignURLParams.MentionSortDirection) || "desc";
  const pageSize = searchParams.get(CampaignURLParams.MentionPageSize) || "50";
  const mentionTypeFilter = searchParams.get(CampaignURLParams.MentionTypeFilter) || "All";
  const dateFilterStart = searchParams.get(CampaignURLParams.DateFilterStart) || "";
  const dateFilterEnd = searchParams.get(CampaignURLParams.DateFilterEnd) || "";
  const requiredFeatures = searchParams.getAll(CampaignURLParams.DiscoverFeatures) || [];

  const maxRank = useMemo(() => getMaxRank(mentions), [mentions]);

  const handleSort = (field: string) => {
    const newParams: Array<[string, string]> = [];
    if (sortOption === field) {
      newParams.push([
        CampaignURLParams.MentionSortDirection,
        sortDirection === "asc" ? "desc" : "asc",
      ]);
    } else {
      newParams.push(
        [CampaignURLParams.MentionSortOption, field],
        [CampaignURLParams.MentionSortDirection, "asc"]
      );
    }
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handlePageSize = (size: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.MentionPageSize, size]];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handleDateFilterStart = (date: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.DateFilterStart, date]];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const handleDateFilterEnd = (date: string) => {
    const newParams: Array<[string, string]> = [[CampaignURLParams.DateFilterEnd, date]];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  // const handleMentionTypeFilter = (option: string) => {
  //   const newParams: Array<[string, string]> = [[CampaignURLParams.MentionTypeFilter, option]];
  //   setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  // };

  const resetFilters = () => {
    const newParams: Array<[string, string | string[]]> = [
      [CampaignURLParams.DateFilterStart, ""],
      [CampaignURLParams.DateFilterEnd, ""],
      // [CampaignURLParams.MentionTypeFilter, "All"],
      [CampaignURLParams.DiscoverFeatures, []],
    ];
    setSearchParams(updateURLParams(searchParams.toString(), newParams), { replace: true });
  };

  const toggleMiniPlayer = () => {
    const newState = !miniPlayer;
    setMiniPlayer(newState);
    localStorage.setItem("miniPlayer", JSON.stringify(newState));
  };

  const sortMentionsData = useMemo(() => {
    return (a: RivrMention, b: RivrMention) => {
      if (!sortOption) return 0;
      let valueA: any = 0;
      let valueB: any = 0;

      if (sortOption === "features") {
        if (a.featRank === undefined && b.featRank !== undefined) {
          return 1;
        } else if (a.featRank !== undefined && b.featRank === undefined) {
          return -1;
        }
        valueA = a.featRank;
        valueB = b.featRank;
      } else {
        valueA = a[sortOption as keyof RivrMention];
        valueB = b[sortOption as keyof RivrMention];
      }

      let comparison = 0;
      if (typeof valueA === "string" && typeof valueB === "string") {
        comparison =
          sortOption === "date"
            ? new Date(valueA).valueOf() - new Date(valueB).valueOf()
            : sortOption === "timestamp"
            ? a["timestamp"] - b["timestamp"]
            : valueA.localeCompare(valueB);
      } else {
        comparison = Number(valueA) - Number(valueB);
      }

      // Back-up sort by rank, then most recent date, alphabetical by channel, first timestamp
      if (comparison === 0) {
        return a["rank"] !== b["rank"]
          ? b["rank"] - a["rank"]
          : a["date"] !== b["date"]
          ? new Date(b["date"]).valueOf() - new Date(a["date"]).valueOf()
          : a["channel"] !== b["channel"]
          ? a["channel"].localeCompare(b["channel"])
          : a["timestamp"] - b["timestamp"];
      }

      return sortDirection === "asc" ? comparison : -comparison;
    };
  }, [searchParams]);

  const filterMentionsData = useMemo(() => {
    return (item: RivrMention) => {
      if (selectedChannels.length > 0 && !selectedChannels.includes(item.channel)) return false;
      if (mentionTypeFilter !== "All" && item.type !== mentionTypeFilter) return false;

      const mentionDate = new Date(item.date);
      const filterStartDateUTC = offsetDate(dateFilterStart);
      const filterEndDateUTC = offsetDate(dateFilterEnd);
      filterEndDateUTC.setDate(filterEndDateUTC.getDate() + 1); // make the end date inclusive
      if (dateFilterStart && mentionDate.valueOf() >= filterStartDateUTC.valueOf() === false)
        return false;
      if (dateFilterEnd && mentionDate.valueOf() < filterEndDateUTC.valueOf() === false)
        return false;

      return true;
    };
  }, [searchParams]);

  const filteredMentions = useMemo(
    () => mentions.filter(filterMentionsData),
    [mentions, filterMentionsData]
  );

  const recalculateRank = (mentions: RivrMention[]) => {
    const result = mentions.map((m) => ({ ...m }));

    result.forEach((mention) => {
      const featRankings: Record<string, number> = mention.sentiment
        ? {
            "ASR positive":
              mention.type === "Speech" && mention.sentiment >= 0 ? mention.sentiment : 0,
            "ASR negative":
              mention.type === "Speech" && mention.sentiment < 0 ? -mention.sentiment : 0,

            "Chat positive":
              mention.type === "Chat" && mention.sentiment >= 0 ? mention.sentiment : 0,
            "Chat negative":
              mention.type === "Chat" && mention.sentiment < 0 ? -mention.sentiment : 0,
          }
        : {};

      mention.rank = mention.rank / maxRank;
      mention.featRank =
        Object.keys(featRankings).length > 0
          ? requiredFeatures.length < 1
            ? Math.abs(mention.sentiment || 0)
            : requiredFeatures.reduce((rnk, feat) => {
                const threshold = (mentionDisplaySettings as any)[feat]?.featureThreshold ?? 0;
                return rnk + (featRankings[feat] >= threshold ? 1 : 0) + (featRankings[feat] ?? 0);
              }, 0)
          : undefined;
    });

    return result;
  };

  const {
    elements,
    totalElements,
    page,
    totalPages,
    setPage,
    nextPage,
    previousPage,
    hasNextPage,
    hasPreviousPage,
    from,
    to,
  } = usePagination<RivrMention>(
    recalculateRank(mentions),
    sortMentionsData,
    filterMentionsData,
    Number(pageSize)
  );

  const features = [
    // Maps to mentionDisplaySettings in models/mention.tsx
    "ASR positive",
    "ASR negative",
    // "Chat positive",
    // "Chat negative",
  ];

  return selectedCampaign === "" ? (
    <HStack w={"100%"} h={"100%"} spacing={4} px={8} py={8} justifyContent={"center"}>
      <Text fontSize="md" color="gray.300" textAlign="center">
        Select a campaign
      </Text>
    </HStack>
  ) : searches.length === 0 ? (
    <HStack w={"100%"} h={"100%"} spacing={4} px={8} py={8} justifyContent={"center"}>
      <Text fontSize="md" color="gray.300" textAlign="center">
        No videos in the selected campaign
      </Text>
    </HStack>
  ) : (
    <VStack
      h={"100%"}
      w={"100%"}
      alignItems={"flex-start"}
      overflowY={"auto"}
      gap={0}
      className={"discover-mentions"}
    >
      <HStack
        justifyContent={"space-between"}
        position={"sticky"}
        top={0}
        p={4}
        bg={"gray.800"}
        zIndex={3}
        w={"100%"}
        pb={4}
      >
        <HStack w="100%" justifyContent={"space-between"}>
          <HStack w="100%" spacing={4} justifyContent={"flex-start"}>
            <DiscoverViewSelect />
            {(isAdmin || mentionsSentimentEnabled) && (
              <>
                <Divider orientation="vertical" h={6} mr={2} />
                <FeatureFilters
                  features={features}
                  urlParameter={CampaignURLParams.DiscoverFeatures}
                />
              </>
            )}
            {/* <Text w={"100%"} noOfLines={1} textAlign={"right"}>
              {mentions.length > 0
                ? `Found ${mentions.length} ${
                    mentions.length === 1 ? "mention" : "mentions"
                  } in ${queryTime} seconds`
                : null}
            </Text> */}
          </HStack>
        </HStack>
      </HStack>

      <VStack w={"100%"} px={4} pb={2} zIndex={2}>
        <HStack w={"100%"} alignItems={"flex-start"}>
          <DiscoverMentionsSearch
            keywords={keywords}
            setKeywords={setKeywords}
            setMentions={setMentions}
            setFailedSearch={setFailedSearch}
            setQueryTime={setQueryTime}
            setMentionsLoading={setMentionsLoading}
          />
          <Box>
            <Menu closeOnSelect={false}>
              <MenuButton as={IconButton} icon={<Filter theme="filled" />} size="sm" />
              <MenuList>
                <MenuGroup title="Date">
                  <HStack px={4} pb={2}>
                    <FormControl>
                      <Input
                        type={"date"}
                        size={"sm"}
                        borderRadius={"md"}
                        value={dateFilterStart}
                        onChange={(e) => handleDateFilterStart(e.target.value)}
                      />
                      <FormHelperText>From</FormHelperText>
                    </FormControl>
                    <FormControl>
                      <Input
                        type={"date"}
                        size={"sm"}
                        borderRadius={"md"}
                        value={dateFilterEnd}
                        onChange={(e) => handleDateFilterEnd(e.target.value)}
                      />
                      <FormHelperText>To</FormHelperText>
                    </FormControl>
                  </HStack>
                </MenuGroup>
                {/* <MenuDivider />
                  <MenuOptionGroup
                    value={mentionTypeFilter}
                    title="Type"
                    type="radio"
                    onChange={(value) => handleMentionTypeFilter(value as "All" | MentionType)}
                  >
                    <MenuItemOption value="All">All</MenuItemOption>
                    <MenuItemOption value="Speech">Speech</MenuItemOption>
                    <MenuItemOption value="Chat">Chat</MenuItemOption>
                  </MenuOptionGroup> */}
                <MenuDivider />
                <MenuGroup>
                  <Button
                    size={"sm"}
                    colorScheme={"gray"}
                    variant={"ghost"}
                    onClick={resetFilters}
                    mx={3}
                    fontWeight={"normal"}
                  >
                    Reset filters
                  </Button>
                </MenuGroup>
              </MenuList>
            </Menu>
          </Box>
          {miniPlayerAllowed && (
            <Tooltip label={"Toggle Mini-player"} placement={"bottom-end"}>
              <IconButton
                size={"sm"}
                aria-label={"Toggle Mini-player"}
                icon={<FullScreenPlay theme="filled" />}
                onClick={toggleMiniPlayer}
                colorScheme={miniPlayer ? "green" : "gray"}
              />
            </Tooltip>
          )}
        </HStack>
        {(isAdmin || hasMentionsReporting) && (
          <MentionsReport
            searches={searches}
            mentions={filteredMentions}
            mentionsLoading={mentionsLoading}
          />
        )}
      </VStack>

      {mentionsLoading ? (
        <Loading />
      ) : elements.length > 0 ? (
        <>
          <TableContainer
            w={"100%"}
            p={4}
            pt={0}
            overflowY={"auto"}
            mt={"0 !important"}
            className={"mentions-table campaign-discover"}
            justifyContent={"space-between"}
            h={"100%"}
            flex={1}
            alignContent={"flex-start"}
          >
            <Table size={"sm"} variant={"simple"}>
              <Thead>
                <Tr h={10}>
                  <Th bg={"gray.800"} onClick={() => handleSort("rank")} w={4} px={2}>
                    Rank
                    <Box display="inline-block" ml={1} width={4}>
                      {sortOption === "rank" &&
                        (sortDirection === "asc" ? (
                          <TriangleUpIcon boxSize={3} />
                        ) : (
                          <TriangleDownIcon boxSize={3} />
                        ))}
                    </Box>
                  </Th>
                  {(isAdmin || mentionsSentimentEnabled) && (
                    <Th
                      bg={"gray.800"}
                      onClick={() => handleSort("features")}
                      w={(features.length - 2) * 8}
                      px={2}
                    >
                      Features
                      <Box display="inline-block" ml={1} width={4}>
                        {sortOption === "features" &&
                          (sortDirection === "asc" ? (
                            <TriangleUpIcon boxSize={3} />
                          ) : (
                            <TriangleDownIcon boxSize={3} />
                          ))}
                      </Box>
                    </Th>
                  )}
                  <Th bg={"gray.800"} onClick={() => handleSort("channel")} w={32} px={2}>
                    Channel
                    <Box display="inline-block" ml={1} width={4}>
                      {sortOption === "channel" &&
                        (sortDirection === "asc" ? (
                          <TriangleUpIcon boxSize={3} />
                        ) : (
                          <TriangleDownIcon boxSize={3} />
                        ))}
                    </Box>
                  </Th>
                  <Th bg={"gray.800"} onClick={() => handleSort("type")} w={16} px={2}>
                    Type
                    <Box display="inline-block" ml={1} width={4}>
                      {sortOption === "type" &&
                        (sortDirection === "asc" ? (
                          <TriangleUpIcon boxSize={3} />
                        ) : (
                          <TriangleDownIcon boxSize={3} />
                        ))}
                    </Box>
                  </Th>
                  <Th bg={"gray.800"} px={2}>
                    Text
                  </Th>
                  <Th bg={"gray.800"} onClick={() => handleSort("date")} w={32} px={2}>
                    Date
                    <Box display="inline-block" ml={1} width={4}>
                      {sortOption === "date" &&
                        (sortDirection === "asc" ? (
                          <TriangleUpIcon boxSize={3} />
                        ) : (
                          <TriangleDownIcon boxSize={3} />
                        ))}
                    </Box>
                  </Th>
                  <Th bg={"gray.800"} onClick={() => handleSort("timestamp")} w={24} px={2}>
                    Timestamp
                    <Box display="inline-block" ml={1} width={4}>
                      {sortOption === "timestamp" &&
                        (sortDirection === "asc" ? (
                          <TriangleUpIcon boxSize={3} />
                        ) : (
                          <TriangleDownIcon boxSize={3} />
                        ))}
                    </Box>
                  </Th>
                  <Th bg={"gray.800"} onClick={() => handleSort("vod")} w={64} px={2}>
                    VOD
                    <Box display="inline-block" ml={1} width={4}>
                      {sortOption === "vod" &&
                        (sortDirection === "asc" ? (
                          <TriangleUpIcon boxSize={3} />
                        ) : (
                          <TriangleDownIcon boxSize={3} />
                        ))}
                    </Box>
                  </Th>
                  <Th bg={"gray.800"} isNumeric w={16} px={2}>
                    Actions
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {elements.map((item, index) => (
                  <DiscoverMentionItem
                    key={index}
                    mention={item}
                    miniPlayer={miniPlayer}
                    isSelected={selectedMention === item}
                    setSelectedMention={setSelectedMention}
                  />
                ))}
              </Tbody>
            </Table>
          </TableContainer>

          <HStack
            py={4}
            bg={"gray.800"}
            mt={"0 !important"}
            pos={"sticky"}
            bottom={0}
            w={"100%"}
            borderTopWidth={1}
            zIndex={1}
          >
            <HStack w={"100%"} justifyContent={"center"}>
              <HStack w={"fit-content"}>
                <Text fontSize={"sm"} color={"gray.400"}>
                  Showing
                </Text>
                <Select
                  size={"sm"}
                  borderRadius={"md"}
                  color={"gray.400"}
                  value={pageSize}
                  minW={"max-content"}
                  variant={"outline"}
                  onChange={(e) => handlePageSize(e.target.value)}
                >
                  <option key={10} value={10}>
                    10
                  </option>
                  <option key={20} value={20}>
                    20
                  </option>
                  <option key={30} value={30}>
                    30
                  </option>
                  <option key={40} value={40}>
                    40
                  </option>
                  <option key={50} value={50}>
                    50
                  </option>
                </Select>
              </HStack>
              <IconButton
                title={"First page"}
                size={"sm"}
                variant={"outline"}
                aria-label={"First page"}
                icon={<DoubleLeft />}
                onClick={() => setPage(1)}
                disabled={page === 1}
              />
              <IconButton
                title={"Previous page"}
                size={"sm"}
                variant={"outline"}
                aria-label={"Previous page"}
                icon={<Left />}
                onClick={previousPage}
                disabled={!hasPreviousPage}
              />
              <HStack px={2}>
                <Text fontSize={"sm"} color={"gray.400"}>
                  Page
                </Text>
                <Input
                  textAlign={"right"}
                  textColor={"gray.400"}
                  value={page}
                  size={"sm"}
                  maxW={14}
                  borderRadius={"md"}
                  type={"number"}
                  onChange={(e) => {
                    setPage(Number(e.target.value));
                  }}
                />
                <Text fontSize={"sm"} color={"gray.400"}>{` of ${totalPages}`}</Text>
              </HStack>
              <IconButton
                title={"Next page"}
                size={"sm"}
                variant={"outline"}
                aria-label={"Next page"}
                icon={<Right />}
                onClick={nextPage}
                disabled={!hasNextPage}
              />
              <IconButton
                title={"Last page"}
                size={"sm"}
                variant={"outline"}
                aria-label={"Last page"}
                icon={<DoubleRight />}
                onClick={() => setPage(totalPages)}
                disabled={page === totalPages}
              />
            </HStack>
          </HStack>
          {miniPlayerAllowed && miniPlayer && selectedMention && (
            <MiniPlayer
              timestamp={secondsToHMSDuration(selectedMention.timestamp)}
              resultsLink={`${RivrLocations.Results}/${selectedMention.searchId}?${updateURLParams(
                searchParams.toString(),
                [
                  [
                    ResultsURLParams.SelectedTimestamp,
                    String(Math.floor(selectedMention.timestamp)),
                  ],
                  [
                    ResultsURLParams.SelectedPanel,
                    selectedMention.type === "Speech" ? "speech" : "chat",
                  ],
                  [ResultsURLParams.SelectedMoment, ""],
                ]
              )}`}
              videoUrl={selectedMention.url}
              toggleVisibility={() => setSelectedMention(null)}
              playerScale={playerScale}
              setPlayerScale={setPlayerScale}
            />
          )}
        </>
      ) : (
        <HStack w={"100%"} h={"100%"} spacing={4} px={8} py={8} justifyContent={"center"}>
          <Text fontSize="md" color="gray.300" textAlign="center">
            {failedSearch
              ? "No mentions found for the provided keywords"
              : mentions.length > 0
              ? "No mentions found for the selected filters"
              : null}
          </Text>
        </HStack>
      )}
    </VStack>
  );
};

export default CampaignDiscoverMentions;
